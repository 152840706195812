<script>
import * as BABYLON from "@babylonjs/core";

//引入@babylonjs/loaders模块，用于加载gltf文件
import "@babylonjs/loaders";

/*************************************************************************************/
/*                                  BABYLONJS                                        */
/*************************************************************************************/

export default {
    name: "Model",
    props: {
        systemKey: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            _engine: null,
            _scene: null,
            _camera: null,
            _sub_system_meshs: [],
            _hl: null,
            newExplosion: null,
            interval: null,
            oldAlpha: null,
            leaderLine: null, //弹窗鼠标连接线对象
        };
    },
    watch: {
      systemKey: {
        handler: "loadSubsystemMeshs",
        immediate: true,
      },
      '$route.path': {
        handler(toPath, fromPath) {
          console.log('路由变化了')
          if (this._scene){
            this._scene.dispose();
          }
        },
        deep: true, // 深度监听
        immediate: true, // 第一次初始化渲染就可以监听到
      },
    },
    methods: {
        initScene() {
            //创建一个canvas，并设置class为“canvas”
            const canvas = document.getElementById("babylonCanvas");

            //  创建 babylonjs 3D 引擎
            const engine = new BABYLON.Engine(canvas);

            engine.displayLoadingUI();

            //  创建一个场景
            const scene = new BABYLON.Scene(engine);

            scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

            //  创建一个摄像头
            const camera = new BABYLON.ArcRotateCamera(
                "camera",
                -Math.PI / 2,
                Math.PI / 2,
                300,
                new BABYLON.Vector3(-70, 40, 20)
            );

            // Enable mouse wheel inputs.
            camera.inputs.addMouseWheel();

            camera.wheelPrecision = 150;

            // 限制摄像头的Y轴旋转范围上限
            camera.upperBetaLimit = Math.PI / 2;
            camera.allowUpsideDown = false;

            // 限制摄像头的距离范围
            // camera.lowerRadiusLimit = 20;
            // camera.upperRadiusLimit = 600;

            // This attaches the camera to the canvas
            camera.attachControl(canvas, true);

            // 创建一个照明灯
            const light = new BABYLON.HemisphericLight(
                "light",
                new BABYLON.Vector3(0, Math.PI / 2.2, 0),
                scene
            );

            // 灯光亮度
            light.intensity = 0.7;

            let hl = new BABYLON.HighlightLayer("hl", scene);

            // 配置渲染管线
            // API  https://doc.babylonjs.com/features/featuresDeepDive/postProcesses/defaultRenderingPipeline
            // Demo https://playground.babylonjs.com/#ECI2Q0#5
            let defaultPipeline = new BABYLON.DefaultRenderingPipeline(
                "DefaultRenderingPipeline",
                true, // is HDR?
                scene,
                scene.cameras
            );

            if (defaultPipeline.isSupported) {
                /* imageProcessing */
                defaultPipeline.imageProcessingEnabled = true; //true by default
                if (defaultPipeline.imageProcessingEnabled) {
                    defaultPipeline.imageProcessing.contrast = 1.2; // 1 by default
                    defaultPipeline.imageProcessing.exposure = 0.6; // 1 by default
                }

                /* glowLayer */
                defaultPipeline.glowLayerEnabled = true;
                if (defaultPipeline.glowLayerEnabled) {
                    defaultPipeline.glowLayer.blurKernelSize = 16; // 16 by default
                    defaultPipeline.glowLayer.intensity = 0.3; // 1 by default
                }
            }

            engine.hideLoadingUI();

            // 建筑透明材质
            let transparentMat = new BABYLON.StandardMaterial("transparentMat");
            transparentMat.alpha = 0.01;
            transparentMat.emissiveColor = new BABYLON.Color3(0.149, 0.357, 0.588);
            transparentMat.wireframe = true;

            // 加载背景地形
            BABYLON.SceneLoader.ImportMesh(
                "",
                "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/bim/82/scds/",
                "scds-framework-skimp-d.glb",
                scene,
                function (meshes) {
                    meshes.forEach((mesh) => {
                        mesh.material = transparentMat;
                    });
                }
            );

            // 不断循环渲染
            engine.runRenderLoop(() => {
                scene.render();
            });

            //渲染完成时
            // scene.executeWhenReady(() => {
            //   this.newExplosion = new BABYLON.MeshExploder(scene.meshes);
            //   this.setIntervalCP();
            // });

            // 当窗口大小改变时，重新渲染
            window.addEventListener("resize", () => {
                engine.resize();
            });

            // 监听鼠标事件
            window.addEventListener("mousemove", function () {
                //获取鼠标选中的模型
                const pickResult = scene.pick(scene.pointerX, scene.pointerY);
                //若选中模型
                if (pickResult.hit) {
                    const info_card_dom = document.getElementById('babylonCanvas_mesh-info-card')
                    if (info_card_dom) {
                        //调整偏移量
                        let cardX = scene.pointerX - 200;
                        let cardY = scene.pointerY - 150;

                        info_card_dom.style.display = 'block'
                        info_card_dom.style.top = (cardY) + 'px'
                        info_card_dom.style.left = (cardX) + 'px'
                    }
                    // console.log('选中模型');
                } else {
                    //未选中模型
                    document.getElementById('babylonCanvas_mesh-info-card').style.display = 'none';
                    // console.log("未选择模型");
                }
            });

            this._scene = scene;
            this._engine = engine;
            this._hl = hl;
            this._camera = camera;
        },
        loadSubsystemMeshs() {
            console.log("system_key", this.$props.systemKey);
            if (this.$props.systemKey && this.$props.systemKey !== "all") {
                console.log("this._sub_system_meshs", this._sub_system_meshs);
                if (this._sub_system_meshs) {
                    this._sub_system_meshs.map((item) => {
                        item.dispose();
                    });
                }

                const sub_system_file = "scds-" + this.$props.systemKey + ".glb";

                // 加载子系统模型
                BABYLON.SceneLoader.ImportMesh(
                    "",
                    "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/bim/82/scds/",
                    sub_system_file,
                    this._scene,
                    (meshes) => {
                        this._sub_system_meshs = meshes;
                        this._hl.addMesh(meshes[0], BABYLON.Color3.Green());
                    }
                );
            }
        },
        //爆炸效果
        explode() {
            this.newExplosion.explode(2); //Explodes meshes away from center. Default 1.0.
        },
        //爆炸复原
        recover() {
            this.newExplosion.explode(0); //Explodes meshes away from center. Default 1.0.
        },
        setIntervalCP() {
            this.oldAlpha = this._camera.alpha;
            //相机自动旋转
            this._scene.activeCamera.useAutoRotationBehavior = true;
        },
        //结束旋转
        clearIntervalCP() {
            this._scene.activeCamera.useAutoRotationBehavior = false;
        },
    },
    mounted() {
        console.log("mounted", this.$props.systemKey);
        this.initScene();
        window.addEventListener("beforeunload", function () {
            this._scene.dispose();
        });
    },
};
</script>

<template>
    <div class="w-100 h-100" style="outline: none !important; border: none !important">
        <div class="position-absolute" style="bottom: 5rem; right: calc(25% + 1rem)">
            <b-button pill size="sm" class="mr-1" @click="explode" variant="danger">爆炸
            </b-button>
            <b-button pill size="sm" class="mr-1" @click="setIntervalCP" variant="primary">开始旋转
            </b-button>
            <b-button pill size="sm" class="mr-1" variant="primary" @click="recover">复原
            </b-button>
            <b-button pill size="sm" variant="primary" @click="clearIntervalCP">结束旋转
            </b-button>
            <b-button pill size="sm" variant="warning" class="ml-1" to="/82/scds/subsystems/all">进入子系统
            </b-button>
        </div>
        <canvas id="babylonCanvas" class="w-100 h-100" style="outline: none !important; border: none !important">
        </canvas>
        <div class="card-all">
            <!-- 模型信息弹窗 -->
            <div id="babylonCanvas_mesh-info-card" class="meshInfoCard" ref="babylonCanvas_mesh-info-card">
                <b-container class="bv-example-row">
                    <b-row align-h="between">
                        <b-col cols="auto">
                            <h4>设备名称</h4>
                        </b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">风机故障报警</b-col>
                        <b-col cols="auto">正常</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">风机手/自动状态</b-col>
                        <b-col cols="auto">自动</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">风机压差状态</b-col>
                        <b-col cols="auto">关闭</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">风机运行状态</b-col>
                        <b-col cols="auto">停机</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">初效过滤报警</b-col>
                        <b-col cols="auto">正常</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">防冻报警</b-col>
                        <b-col cols="auto">正常</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">送风湿度</b-col>
                        <b-col cols="auto">59.57%</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">送风温度</b-col>
                        <b-col cols="auto">20.34℃</b-col>
                    </b-row>
                    <b-row align-h="between">
                        <b-col cols="auto">中效过滤报警</b-col>
                        <b-col cols="auto">正常</b-col>
                    </b-row>
                </b-container>
            </div>
            <!-- 创建一个坐标点 -->
            <div id="point" class="point"></div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.meshInfoCard {
    position: fixed;
    z-index: 99999;
    display: none;
    color: whitesmoke;
    top: 0;
    width: 300px;
    background-color: rgba(135, 206, 250, 0.5);
    padding: 1rem 0.5rem;
    border-radius: 4px;
    backdrop-filter: blur(1px);
}

/* 样式化坐标点 */
.point {
    visibility: hidden;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: fixed;
    z-index: 99999;
}
</style>
