<template>
    <div class="position-relative">
        <div>
            <h1 class="font-title text-center mb-0" style="
                  font-size: 2rem;
                ">
                北京四川大厦
            </h1>
            <p class="text-center">
                Beijing Sichuan Mansion Building
            </p>
        </div>

        <div class="w-100" style="height: calc(100vh - 102px)">
            <Model />
        </div>

        <div class="w-25 position-absolute" style="top: 1rem; left: 0">
            <b-card class="card-border-custom">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-lightning-fill class="mr-1" variant="primary" />今日能耗
                </b-card-title>
                <b-row>
                    <b-col cols="4">
                        <b-alert show variant="danger" class="mb-0">
                            <div class="alert-body text-center py-2 px-0" id="tooltip-target-1">
                                <b-icon-lightning-fill font-scale="1.5" />
                                <p>耗电量</p>
                                <h1 class="text-danger text-truncate mb-0">
                                    4468.5
                                </h1>
                                <label>kWh</label>
                                <b-tooltip target="tooltip-target-1" triggers="hover" variant="danger">
                                    截至
                                    <span>2024-06-06 10:30:02</span>
                                </b-tooltip>
                            </div>
                        </b-alert>
                    </b-col>

                    <b-col cols="4">
                        <b-alert show variant="success" class="mb-0">
                            <div class="alert-body text-center py-2 px-0" id="tooltip-target-2">
                                <b-icon-sunrise-fill font-scale="1.5" />
                                <p>发电量</p>
                                <h1 class="text-success text-truncate mb-0">
                                    5043.2
                                </h1>
                                <label>kWh</label>
                                <b-tooltip target="tooltip-target-2" triggers="hover" variant="success">
                                    截至
                                    <span>2024-06-06 10:30:02</span>
                                </b-tooltip>
                            </div>
                        </b-alert>
                    </b-col>

                    <b-col cols="4">
                        <b-alert show variant="warning" class="mb-0">
                            <div class="alert-body text-center py-2 px-0" id="tooltip-target-3">
                                <b-icon-safe-fill font-scale="1.5" />
                                <p>用水量</p>
                                <h1 class="text-warning text-truncate mb-0">
                                    31.2
                                </h1>
                                <label>m<sup>3</sup></label>
                                <b-tooltip target="tooltip-target-3" triggers="hover" variant="warning">
                                    截至
                                    <span>2024-06-06 10:30:02</span>
                                </b-tooltip>
                            </div>
                        </b-alert>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="card-border-custom">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-tree-fill class="mr-1" variant="primary" />今日碳排
                </b-card-title>
                <b-row>
                    <b-col cols="6">
                        <div class="card-border-custom p-2">
                            <div class="d-flex align-items-center">
                                <b-avatar rounded variant="light-info" class="mr-1" size="48">
                                    <b-icon-graph-up scale="2" />
                                </b-avatar>
                                <div>
                                    碳排放<br />
                                    <span class="h3 text-info">3951.5</span><br />
                                    <small>&nbsp;kgCO<sub>2</sub></small>
                                </div>
                            </div>
                            <div class="mt-50 text-truncate"><small>≈ 燃烧标煤<b-badge
                                        variant="light-info">5.8</b-badge>t</small>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="6">
                        <div class="card-border-custom p-2">
                            <div class="d-flex align-items-center">
                                <b-avatar rounded variant="light-success" class="mr-1" size="48">
                                    <b-icon-graph-down scale="2" />
                                </b-avatar>
                                <div>
                                    减碳量<br />
                                    <span class="h3 text-success">508.2</span><br />
                                    <small>&nbsp;kgCO<sub>2</sub></small>
                                </div>
                            </div>
                            <div class="mt-50 text-truncate"><small>≈ 植树造林<b-badge
                                        variant="light-success">13.9</b-badge>m<sup>2</sup></small>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="card-border-custom">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-pie-chart-fill class="mr-1" variant="primary" />分项能耗
                </b-card-title>
                <div style="height: 11rem" id="container2"></div>
            </b-card>

            <b-card class="card-border-custom mb-0">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-bar-chart-fill class="mr-1" variant="primary" />逐月能耗
                </b-card-title>
                <div style="height: 11rem" id="container1"></div>
            </b-card>
        </div>

        <div class="w-25 position-absolute" style="top: 1rem; right: 0">
            <b-card class="card-border-custom">
                <b-card-title class="card-title-custom text-truncate">
                    <b-icon-gear-fill class="mr-1" variant="primary" />
                    设备状态
                </b-card-title>
                <b-card-text>
                    <b-row>
                        <b-col cols="6">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <b-icon-hdd-rack-fill />
                                    <span class="ml-1">制冷机</span>
                                </div>
                                <div>
                                    <span class="bullet bullet-primary bullet-sm"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-gear-wide-connected />
                                    <span class="ml-1">冷冻泵</span>
                                </div>
                                <div>
                                    <span class="bullet bullet-secondary bullet-sm"></span>
                                    <span class="bullet bullet-primary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-gear-wide-connected />

                                    <span class="ml-1">冷却泵</span>
                                </div>
                                <div>
                                    <span class="bullet bullet-secondary bullet-sm"></span>
                                    <span class="bullet bullet-primary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-warning bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-server />
                                    <span class="ml-1">冷却塔</span>
                                </div>
                                <div>
                                    <span class="bullet bullet-secondary bullet-sm"></span>
                                    <span class="bullet bullet-primary bullet-sm ml-50"></span>
                                    <span class="bullet bullet-secondary bullet-sm ml-50"></span>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <b-icon-wind />
                                    <span class="ml-1">空调机</span>
                                </div>
                                <div class="text-muted">
                                    <span class="text-primary" style="font-weight: bold">5</span> / 10
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-asterisk />
                                    <span class="ml-1">新风机</span>
                                </div>
                                <div class="text-muted">
                                    <span class="text-primary" style="font-weight: bold">6</span> / 12
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-broadcast />
                                    <span class="ml-1">送风机</span>
                                </div>
                                <div class="text-muted">
                                    <span class="text-primary" style="font-weight: bold">4</span> / 9
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-75">
                                <div>
                                    <b-icon-bullseye />
                                    <span class="ml-1">排风机</span>
                                </div>
                                <div class="text-muted">
                                    <span class="text-primary" style="font-weight: bold">4</span> / 8
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card class="card-border-custom">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-sticky-fill class="mr-1" variant="primary" />工单统计
                </b-card-title>
                <b-row>
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-danger"><b-icon-exclamation-triangle-fill /></b-avatar>
                            <h1 class="text-danger mb-0" style="font-weight: bold">0</h1>
                            设备报警
                        </div>
                    </b-col>
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-warning"><b-icon-cone-striped /></b-avatar>
                            <h1 class="text-warning mb-0" style="font-weight: bold">0</h1>
                            消防报警
                        </div>
                    </b-col>
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-info"><b-icon-broadcast-pin /></b-avatar>
                            <h1 class="text-info mb-0" style="font-weight: bold">0</h1>
                            安防报警
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-success"><b-icon-clipboard-check /></b-avatar>
                            <h1 class="text-success mb-0" style="font-weight: bold">412</h1>
                            巡检数量
                        </div>
                    </b-col>
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-primary"><b-icon-tools /></b-avatar>
                            <h1 class="text-primary mb-0" style="font-weight: bold">4</h1>
                            报修数量
                        </div>
                    </b-col>
                    <b-col cols="4">
                        <div class="card-border-custom text-center p-75">
                            <b-avatar variant="light-danger"><b-icon-chat-dots-fill /></b-avatar>
                            <h1 class="text-danger mb-0" style="font-weight: bold">0</h1>
                            投诉数量
                        </div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="card-border-custom">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-speedometer2 class="mr-1" variant="primary" />健康度
                </b-card-title>
                <b-row>
                    <b-col cols="4">
                        <b-alert show variant="primary" class="mt-3">
                            <div class="alert-body">
                                <h1 style="font-size: 2.5rem" class="mb-0">
                                    85%
                                </h1>
                                <b-icon-star-fill variant="success"></b-icon-star-fill>
                                <b-icon-star-fill variant="success"></b-icon-star-fill>
                                <b-icon-star-fill variant="success"></b-icon-star-fill>
                                <b-icon-star-half variant="success"></b-icon-star-half>
                                <b-icon-star variant="success"></b-icon-star>
                            </div>
                        </b-alert>
                    </b-col>
                    <b-col cols="8">
                        <div style="height: 11rem" id="container3"></div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="card-border-custom mb-0">
                <b-card-title class="text-truncate card-title-custom">
                    <b-icon-thermometer-half class="mr-1" variant="primary" />室内环境
                </b-card-title>
                <b-row>
                    <b-col cols="6">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <b-avatar rounded variant="light-warning" class="mr-1">
                                    <b-icon-thermometer-half />
                                </b-avatar>
                                温度
                            </div>
                            <b-badge variant="light-warning">26 ℃</b-badge>
                        </div>
                    </b-col>
                    <b-col cols="6">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <b-avatar rounded variant="light-info" class="mr-1">
                                    <b-icon-thermometer-half />
                                </b-avatar>
                                湿度
                            </div>
                            <b-badge variant="light-info">50 %</b-badge>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-50">
                    <b-col cols="6">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <b-avatar rounded variant="light-success" class="mr-1">
                                    <b-icon-thermometer-half />
                                </b-avatar>
                                CO<sub>2</sub>
                            </div>
                            <b-badge variant="light-success">265 ppm</b-badge>
                        </div>
                    </b-col>
                    <b-col cols="6">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <b-avatar rounded variant="light-danger" class="mr-1">
                                    <b-icon-thermometer-half />
                                </b-avatar>
                                PM<sub>2.5</sub>
                            </div>
                            <b-badge variant="light-danger">35 μg/m<sup>3</sup></b-badge>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>


<script>
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@/@core/utils/filter";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
import { momentJs } from "@/libs/utils/moment";
import { getHotColdLoad } from "@/api/xj";
import Model from "./components/model.vue";
import { Column, Pie, Line, Area, Gauge } from "@antv/g2plot";
import { RGBADepthPacking } from "three";



export default {
    name: "Dashboard",
    components: {
        Model,
    },
    props: {
        data: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {

        };
    },
    watch: {},
    methods: {
        initColumnChart() {
            const data = [
                {
                    type: '2023-01',
                    sales: 489074,
                },
                {
                    type: '2023-02',
                    sales: 461176,
                },
                {
                    type: '2023-03',
                    sales: 437525,
                },
                {
                    type: '2023-04',
                    sales: 364109,
                },
                {
                    type: '2023-05',
                    sales: 488957,
                },
                {
                    type: '2023-06',
                    sales: 665284,
                },
                {
                    type: '2023-07',
                    sales: 801562,
                },
                {
                    type: '2023-08',
                    sales: 854351,
                },
                {
                    type: '2023-09',
                    sales: 695365,
                },
                {
                    type: '2023-10',
                    sales: 379483,
                },
                {
                    type: '2023-11',
                    sales: 482338,
                },
                {
                    type: '2023-12',
                    sales: 553317,
                },
                {
                    type: '2024-01',
                    sales: 547861,
                },
                {
                    type: '2024-02',
                    sales: 467864,
                },
                {
                    type: '2024-03',
                    sales: 443886,
                },
                {
                    type: '2024-04',
                    sales: 388995,
                },
                {
                    type: '2024-05',
                    sales: 505809,
                },
                {
                    type: '2024-06',
                    sales: 134160,
                },
            ];

            const columnPlot = new Column(document.getElementById('container1'), {
                title: {
                    visible: true,
                    text: '逐月能耗',
                },
                forceFit: true,
                data,
                xField: 'type',
                yField: 'sales',
                color: 'l(90) 0:#1890ffff 1:#1890ff00',
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                    line: null,
                    grid: null,
                    tickLine: null,
                },
                yAxis: {
                    line: null,
                    grid: null,
                    label: null,
                },
                meta: {
                    type: {
                        alias: '月份',
                    },
                    sales: {
                        alias: '能耗（kWh）',
                    },
                },
            });

            columnPlot.render();

        },
        initPieChart() {
            const data = [
                {
                    type: '空调用电',
                    value: 2587.7,
                },
                {
                    type: '照明用电',
                    value: 1146.2,
                },
                {
                    type: '插座用电',
                    value: 595.2,
                },
                {
                    type: '其它用电',
                    value: 139.4,
                },
            ];

            const piePlot = new Pie(document.getElementById('container2'), {
                forceFit: true,
                title: {
                    visible: true,
                    text: '分项能耗',
                },
                theme: {
                    colors10: Object.values($themeColors),
                },
                radius: 0.9,
                innerRadius: 0.6,
                data,
                angleField: 'value',
                colorField: 'type',
                label: {
                    type: "inner",
                    offset: "-50%",
                    autoRotate: false,
                    style: {
                        textAlign: "center",
                        fill: "#c5c8ce",
                    },
                    formatter: ({ percent }) =>
                        `${(percent * 100).toFixed(0)}%`,
                },
                legend: {
                    position: "left",
                    itemName: {
                        style: {
                            fill: "#808695",
                        },
                    },
                },
                pieStyle: {
                    lineWidth: 0,
                },
                statistic: {
                    title: {
                        formatter: () => "合计",
                        offsetY: -8,
                        style: {
                            fontSize: "1rem",
                        },
                    },
                    content: {
                        offsetY: -4,
                        style: {
                            fontSize: "1.286rem",
                            fontWeight: "normal",
                        },
                    },
                },
                interactions: [
                    {
                        type: "element-selected",
                    },
                    {
                        type: "element-highlight",
                    },
                    {
                        type: "pie-statistic-active",
                        cfg: {
                            start: [
                                {
                                    trigger: "element:mouseenter",
                                    action: "pie-statistic:change",
                                },
                                {
                                    trigger: "legend-item:mouseenter",
                                    action: "pie-statistic:change",
                                },
                            ],
                            end: [
                                {
                                    trigger: "element:mouseleave",
                                    action: "pie-statistic:reset",
                                },
                                {
                                    trigger: "legend-item:mouseleave",
                                    action: "pie-statistic:reset",
                                },
                            ],
                        },
                    },
                ],
            });

            piePlot.render();
        },
        initAreaChart() {
            const data = [
                {
                    time: '00:00',
                    type: '实测值',
                    value: 4623,
                },
                {
                    time: '00:00',
                    type: 'MLP预测',
                    value: 2208,
                },
                {
                    time: '00:00',
                    type: 'XGB预测',
                    value: 182,
                },
                {
                    time: '01:00',
                    type: '实测值',
                    value: 6145,
                },
                {
                    time: '01:00',
                    type: 'MLP预测',
                    value: 2016,
                },
                {
                    time: '01:00',
                    type: 'XGB预测',
                    value: 257,
                },
                {
                    time: '02:00',
                    type: '实测值',
                    value: 508,
                },
                {
                    time: '02:00',
                    type: 'MLP预测',
                    value: 2916,
                },
                {
                    time: '02:00',
                    type: 'XGB预测',
                    value: 289,
                },
                {
                    time: '03:00',
                    type: '实测值',
                    value: 6268,
                },
                {
                    time: '03:00',
                    type: 'MLP预测',
                    value: 4512,
                },
                {
                    time: '03:00',
                    type: 'XGB预测',
                    value: 428,
                },
                {
                    time: '04:00',
                    type: '实测值',
                    value: 6411,
                },
                {
                    time: '04:00',
                    type: 'MLP预测',
                    value: 8281,
                },
                {
                    time: '04:00',
                    type: 'XGB预测',
                    value: 619,
                },
                {
                    time: '05:00',
                    type: '实测值',
                    value: 1890,
                },
                {
                    time: '05:00',
                    type: 'MLP预测',
                    value: 2008,
                },
                {
                    time: '05:00',
                    type: 'XGB预测',
                    value: 87,
                },
                {
                    time: '06:00',
                    type: '实测值',
                    value: 4251,
                },
                {
                    time: '06:00',
                    type: 'MLP预测',
                    value: 1963,
                },
                {
                    time: '06:00',
                    type: 'XGB预测',
                    value: 706,
                },
                {
                    time: '07:00',
                    type: '实测值',
                    value: 2978,
                },
                {
                    time: '07:00',
                    type: 'MLP预测',
                    value: 2367,
                },
                {
                    time: '07:00',
                    type: 'XGB预测',
                    value: 387,
                },
                {
                    time: '08:00',
                    type: '实测值',
                    value: 3880,
                },
                {
                    time: '08:00',
                    type: 'MLP预测',
                    value: 2956,
                },
                {
                    time: '08:00',
                    type: 'XGB预测',
                    value: 488,
                },
                {
                    time: '09:00',
                    type: '实测值',
                    value: 3606,
                },
                {
                    time: '09:00',
                    type: 'MLP预测',
                    value: 678,
                },
                {
                    time: '09:00',
                    type: 'XGB预测',
                    value: 507,
                },
                {
                    time: '10:00',
                    type: '实测值',
                    value: 4311,
                },
                {
                    time: '10:00',
                    type: 'MLP预测',
                    value: 3188,
                },
                {
                    time: '10:00',
                    type: 'XGB预测',
                    value: 548,
                },
                {
                    time: '11:00',
                    type: '实测值',
                    value: 4116,
                },
                {
                    time: '11:00',
                    type: 'MLP预测',
                    value: 3491,
                },
                {
                    time: '11:00',
                    type: 'XGB预测',
                    value: 456,
                },
                {
                    time: '12:00',
                    type: '实测值',
                    value: 6419,
                },
                {
                    time: '12:00',
                    type: 'MLP预测',
                    value: 2852,
                },
                {
                    time: '12:00',
                    type: 'XGB预测',
                    value: 689,
                },
                {
                    time: '13:00',
                    type: '实测值',
                    value: 1643,
                },
                {
                    time: '13:00',
                    type: 'MLP预测',
                    value: 4788,
                },
                {
                    time: '13:00',
                    type: 'XGB预测',
                    value: 280,
                },
                {
                    time: '14:00',
                    type: '实测值',
                    value: 445,
                },
                {
                    time: '14:00',
                    type: 'MLP预测',
                    value: 4319,
                },
                {
                    time: '14:00',
                    type: 'XGB预测',
                    value: 176,
                },
            ];

            const areaPlot = new Area(document.getElementById('container3'), {
                theme: {
                    colors10: Object.values($themeColors),
                },
                legend: {
                    itemName: {
                        style: { fill: "#82868b" },
                    },
                    position: "top",
                },
                data,
                xField: "time",
                yField: "value",
                seriesField: "type",
                isStack: false,
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                    line: null,
                    grid: null,
                    tickLine: null,
                },
                yAxis: {
                    nice: true,
                    grid: null,
                    label: null,
                },
                smooth: true,
                meta: {
                    value: {
                        alias: "数值",
                        formatter: (value) => {
                            return Math.round(value * 100) / 100;
                        },
                    },
                },
            });
            areaPlot.render();
        },
        initGaugeChart() {
            const gaugePlot = new Gauge("container3", {
                percent: 0.85,
                type: "meter",
                innerRadius: 0.75,
                range: {
                    ticks: [0, 1 / 3, 2 / 3, 1],
                    color: ["#ea5455", "#ff9f43", "#28c76f"],
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: "#D0D0D0",
                        },
                    },
                    pin: {
                        style: {
                            stroke: "#D0D0D0",
                        },
                    },
                },
                axis: {
                    label: {
                        formatter(v) {
                            return Number(v) * 100;
                        },
                    },
                    subTickLine: {
                        count: 3,
                    },
                },
            });
            gaugePlot.render();
        }
    },
    mounted() {
        this.initColumnChart();
        this.initPieChart();
        /* this.initAreaChart(); */
        this.initGaugeChart();
    },
    destroyed() {
    },


};
</script>

<style lang="scss" scoped>
.adsk-viewing-viewer {
    background: none !important;
}

.homeViewWrapper {
    display: none !important;
}

.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}


.card-border-custom {
    border-radius: 0;
    box-shadow: 0 0 1.5vw rgba(24, 144, 255, 0.2) inset;
    background:
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) left top,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) left top,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) right top,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) right top,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) left bottom,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) left bottom,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) right bottom,
        linear-gradient(rgba(24, 144, 255, 0.8), rgba(24, 144, 255, 0.8)) right bottom;
    background-repeat: no-repeat;
    background-size: 0.1vw 0.5vw, 0.5vw 0.1vw;
}

.card-title-custom {
    border-left: 0;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: -.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: .5rem;
    background-image: linear-gradient(rgba(24, 144, 255, 0), rgba(24, 144, 255, 0.2));
    border-bottom: 1px solid rgba(24, 144, 255, 0.7)
}
</style>
